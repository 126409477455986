import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addMedia, updateMedia } from "../../../store/actions/mediaActions";

function UploadMediaModal({ show, closeModal, mediaType, activeItem }) {
  const [file, setFile] = useState(null);
  const [dominantColor, setDominantColor] = useState();

  const dispatch = useDispatch();

  const handleUpload = async () => {
    if (activeItem) {
      await dispatch(updateMedia(activeItem.name, dominantColor));
    } else {
      await dispatch(addMedia(file, mediaType, dominantColor));
    }

    closeModal();
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleDominantColor = (event) => {
    setDominantColor(event.target.value);
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload media</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!activeItem && (
          <Form.Group controlId="formFileSm" className="mb-3">
            <Form.Label>File</Form.Label>
            <Form.Control type="file" size="sm" onChange={handleChange} />
          </Form.Group>
        )}

        <Form.Group controlId="dominantColor" className="mb-3">
          <Form.Label>Dominant color</Form.Label>
          <Form.Control
            type="color"
            defaultValue={
              activeItem && activeItem.dominantColor !== undefined
                ? activeItem.dominantColor
                : "#ffffff"
            }
            title="Choose your color"
            onChange={handleDominantColor}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" size="sm" onClick={handleUpload}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export { UploadMediaModal };
