import styled from "styled-components";
import { motion } from "framer-motion";

const StyledFooter = styled(motion.div)`
  padding: 20px 0;

  .copyright {
    font-size: 1.25rem;
    font-weight: 400;

    @media (max-width: 576px) {
      font-size: 0.8rem;
      margin-top: 10px;
    }
  }

  .footer-links a {
    color: #1c1c1c;
    font-size: 1.25rem;
    font-weight: 400;

    .dark & {
      color: #ffffff;
    }

    @media (max-width: 576px) {
      font-size: 1rem;
    }
  }

  .modal-open & {
    filter: blur(7px);
  }
`;

const StyledGetInTouch = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
`;

export { StyledFooter, StyledGetInTouch };
