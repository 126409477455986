import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { NavLink } from "react-router-dom";
import { StyledNavbar, StyledLogo } from "./Navbar.styled";
import { BurgerMenu } from "../burger-menu/BurgerMenu";
import { NavMenu } from "../nav-menu/NavMenu";

function Navbar() {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["dark-mode"]);

  useEffect(() => {
    setDarkMode(!!cookies["dark-mode"]);

    if (cookies["dark-mode"]) {
      document.body.classList.add("dark");
    }
  }, [cookies]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);

    if (!darkMode) {
      setCookie("dark-mode", !darkMode, { path: "/" });
    } else {
      removeCookie("dark-mode");
    }

    document.body.classList.toggle("dark");
  };

  const handleOpenMobileMenu = (isOpened) => {
    setOpenMobileMenu(isOpened);

    if (isOpened) {
      document.body.classList.add("menu-opened");
    } else {
      document.body.classList.remove("menu-opened");
    }
  };

  return (
    <StyledNavbar className="navbar">
      <Container>
        <StyledLogo className="ms-0 ms-sm-2">
          <NavLink to="/">
            {!darkMode ? (
              <img src="/logo.svg" alt="SeeSharp" />
            ) : (
              <img src="/logo-dark.svg" alt="SeeSharp" />
            )}
          </NavLink>
        </StyledLogo>

        <BurgerMenu open={openMobileMenu} setOpen={handleOpenMobileMenu} />

        <NavMenu
          openMobileMenu={openMobileMenu}
          setOpenMobileMenu={handleOpenMobileMenu}
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
        />
      </Container>
    </StyledNavbar>
  );
}

export { Navbar };
