import React, { Fragment } from "react";
import { Button, Row, Col } from "react-bootstrap";

function AccordionsList({ deleteAccordion, accordions }) {
  return (
    <>
      <Row className="mt-3">
        <Col md="6">
          {accordions &&
            accordions.map((accordion, index) => (
              <Fragment key={index}>
                <div className="d-flex">
                  <div className="me-3">
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => deleteAccordion(index)}
                    >
                      Delete
                    </Button>
                  </div>
                  <div>
                    <div>
                      <strong>{accordion.title}</strong>
                    </div>
                    <div>{accordion.content}</div>
                  </div>
                </div>
                <hr />
              </Fragment>
            ))}
        </Col>
      </Row>
    </>
  );
}

export { AccordionsList };
