import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { PageItem } from "./PageItem";
import { sortItems } from "../../../_helpers/functions";
import { updatePageItemsPosition } from "../../../store/actions/pageItemsActions";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ItemsList = React.memo(function ItemsList({
  items,
  canDrag,
  setActiveItem,
}) {
  return items.map((item, index) => (
    <PageItem
      index={index}
      pageItem={item}
      canDrag={canDrag}
      setActiveItem={setActiveItem}
      key={item.id}
    />
  ));
});

function ItemsContainer({ area, title, setActiveItem }) {
  const [items, setItems] = useState([]);
  const [canDrag, setCanDrag] = useState(false);

  const { data } = useSelector((state) => state.pageItems);

  const dispatch = useDispatch();

  useEffect(() => {
    setItems(
      data
        .filter((item) => item.destination.includes(area))
        .sort(sortItems(area))
    );
  }, [data]);

  useEffect(() => {
    if (!canDrag) {
      items.map((card, idx) => {
        card.index[area] = idx;
      });

      dispatch(updatePageItemsPosition(items));
    }
  }, [canDrag]);

  const toggleCanDrag = () => setCanDrag(!canDrag);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const itemsReordered = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(itemsReordered);
  }

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex">
        {title}
        <Button
          variant="primary"
          size="sm"
          onClick={toggleCanDrag}
          className="ms-auto"
        >
          {canDrag ? "Finish" : "Reorder"}
        </Button>
      </Card.Header>

      <Card.Body className="">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <ItemsList
                  items={items}
                  canDrag={canDrag}
                  setActiveItem={setActiveItem}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card.Body>
    </Card>
  );
}

export { ItemsContainer };
