import { API_URL } from "../constants";
import { fetchWrapper } from "../_helpers";

// TODO: Use it from a config file
const baseUrl = `${API_URL}/media`;

export const mediaService = {
  getAll,
  deleteMedia,
  addMedia,
  updateMedia,
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function deleteMedia(filename) {
  return fetchWrapper.delete(`${baseUrl}/${filename}`);
}

function addMedia(file, folder, dominantColor) {
  return fetchWrapper.post(baseUrl, { file, folder, dominantColor }, true);
}

function updateMedia(filename, dominantColor) {
  return fetchWrapper.put(baseUrl, { filename, dominantColor }, true);
}
