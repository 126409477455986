import * as ACTION from "../constants";
import { pageItemService } from "../../_services";
import { dispatch } from "rxjs/internal/observable/pairs";

export const clearPageItems = () => (dispatch) => {
  dispatch({ type: ACTION.CLEAR_PAGE_ITEMS });
};

export const fetchPagesData = () => async (dispatch, getState) => {
  const state = getState();

  if (state.pageItems.data.length > 0) {
    return;
  }

  dispatch({ type: ACTION.FETCH_PAGES_DATA });

  const data = await pageItemService.getItems();

  dispatch({
    type: ACTION.FETCH_PAGES_DATA_SUCCESS,
    payload: data,
  });
};

export const addPageItem = (payload) => async (dispatch) => {
  dispatch({ type: ACTION.ADD_PAGES_DATA });

  const data = await pageItemService.addItem(payload);

  dispatch({
    type: ACTION.ADD_PAGES_DATA_SUCCESS,
    payload: data,
  });
};

export const updatePageItemsPosition = (payload) => async (dispatch) => {
  dispatch({ type: ACTION.UPDATE_PAGES_DATA });

  if (payload.length) {
    const data = await pageItemService.updatePosition(payload);

    payload.map((item) =>
      dispatch({
        type: ACTION.UPDATE_PAGES_DATA_SUCCESS,
        payload: item,
      })
    );
  }
};

export const updatePageItem = (payload) => async (dispatch) => {
  dispatch({ type: ACTION.UPDATE_PAGES_DATA });

  const data = await pageItemService.update(payload);

  dispatch({ type: ACTION.UPDATE_PAGES_DATA_SUCCESS, payload });
};
