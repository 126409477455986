import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { StyledForm } from "./ContactForm.styled";
import { contactService } from "../../_services/contact.service";

function ContactForm() {
  const [validated, setValidated] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Send message");
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [displayLoading, setDisplayLoading] = useState(false);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setFormIsSubmitted(true);
      setDisplayLoading(true);
      setSubmitBtnText("Message is sending");

      const formData = new FormData(event.target);
      const formDataObj = Object.fromEntries(formData.entries());

      await contactService.sendMessage(formDataObj);

      setSubmitBtnText("Message sent");
      setDisplayLoading(false);

      form.reset();

      setTimeout(() => form.classList.remove("was-validated"), 1);
    }

    setValidated(true);
  };

  return (
    <StyledForm
      noValidate
      validated={validated}
      className="contact-form mt-4"
      onSubmit={handleSubmit}
      onClick={(e) => e.stopPropagation()}
    >
      <Form.Group>
        <Form.Control placeholder="Your name" required name="name" />
        <Form.Control.Feedback type="invalid">
          Name is required
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Control
          type="email"
          placeholder="Email"
          required
          name="email"
          className="mt-5"
        />
        <Form.Control.Feedback type="invalid">
          Email is missing or is invalid
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Control
          placeholder="Phone"
          required
          name="phone"
          className="mt-5"
        />
        <Form.Control.Feedback type="invalid">
          Phone is required
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Control
          as="textarea"
          placeholder="Message"
          required
          name="message"
          className="mt-5"
        />
        <Form.Control.Feedback type="invalid">
          Message is required
        </Form.Control.Feedback>
      </Form.Group>

      <div className="mt-5">
        <Form.Group>
          <Form.Check
            type="checkbox"
            className="terms"
            required
            name="terms"
            label="I agree that Name, Email address and phone I fill in the form, to be used by receiving informations from seesharpstudio.com"
          />
          <Form.Control.Feedback type="invalid">
            Terms are required!
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className="text-center text-lg-left">
        <Button
          type="submit"
          variant="link"
          className="mt-4 mt-lg-5"
          disabled={formIsSubmitted}
        >
          {displayLoading && (
            <Spinner animation="border" size="sm" className="me-2" />
          )}

          {submitBtnText}
        </Button>
      </div>
    </StyledForm>
  );
}

export { ContactForm };
