import React, { useEffect } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import Player from "@vimeo/player";

import { StyledContainer, ArticleContent } from "../ItemDetails.styled";

function ItemArticle({ item }) {
  useEffect(() => {
    if (item.video) {
      const player = new Player("main-video", {
        url: item.video,
        loop: item.videoEnableLoop,
        controls: !item.videoHideControls,
        responsive: true,
        muted: true,
      });

      player.setMuted();

      if (item.videoAutoPlay) {
        player.play();
      }
    }

    if (item.galleryMainVideo) {
      const galleryPlayer = new Player("main-gallery-video", {
        url: item.galleryMainVideo,
        loop: item.galleryMainImageEnableLoop,
        controls: !item.galleryMainImageHideControls,
        responsive: true,
        muted: true,
      });
      galleryPlayer.setMuted();
      if (item.galleryMainImageAutoPlay) {
        galleryPlayer.play();
      }
    }
  }, []);

  return (
    <StyledContainer isArticle>
      <ArticleContent onClick={(e) => e.stopPropagation()}>
        <Row>
          <Col
            xl={4}
            className="d-flex flex-column justify-content-center order-2 order-xl-1 mt-5 mt-lg-0"
          >
            <h2 className="h1 main-title d-none d-xl-block">{item.title}</h2>

            <p className="short-description">{item.shortDescription}</p>

            {item.services ? (
              <div className="services mt-5">
                <span className="d-block">Services</span>
                <p>{item.services}</p>
              </div>
            ) : null}
          </Col>
          <Col xl={8} className="order-1 order-xl-2">
            <h2 className="h1 main-title d-block d-xl-none">{item.title}</h2>
            {item.video ? (
              <div id="main-video" style={{ width: "100%" }} />
            ) : (
              <img src={item.image.url} alt="" className="img-fluid" />
            )}
          </Col>
        </Row>

        {item.description ? (
          <Row className="mt-5">
            {item.additionalDescription && (
              <Col xl={4}>
                <p className="section-title">{item.additionalDescription}</p>
              </Col>
            )}

            <Col xl={item.additionalDescription ? 8 : 12}>
              <p>{item.description}</p>
            </Col>
          </Row>
        ) : null}

        {item.accordions && item.accordions.length ? (
          <Row className="mt-5">
            {item.additionalAccordion && (
              <Col xl={4}>
                <p className="section-title additional-accordion">
                  {item.additionalAccordion}
                </p>
              </Col>
            )}

            <Col xl={item.additionalAccordion ? 8 : 12}>
              <Accordion defaultActiveKey="0" flush>
                {item.accordions.map((accordion, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{accordion.title}</Accordion.Header>
                    <Accordion.Body>{accordion.content}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        ) : null}

        {item.galleryFirstImage || item.gallerySecondImage ? (
          <Row className="mt-5">
            {item.galleryFirstImage ? (
              <Col lg={6}>
                <img
                  src={item.galleryFirstImage.url}
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            ) : null}

            {item.gallerySecondImage ? (
              <Col lg={6} className="mt-4 mt-lg-0">
                <img
                  src={item.gallerySecondImage.url}
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            ) : null}
          </Row>
        ) : null}

        {item.galleryMainImage ? (
          <Row className="mt-4">
            <Col>
              <img
                src={item.galleryMainImage.url}
                alt=""
                className="img-fluid w-100"
              />
            </Col>
          </Row>
        ) : null}

        {item.galleryMainVideo ? (
          <Row className="mt-4">
            <Col>
              <div id="main-gallery-video" style={{ width: "100%" }} />
            </Col>
          </Row>
        ) : null}

        {item.galleryThirdImage || item.galleryFourthImage ? (
          <Row className="mt-4">
            {item.galleryThirdImage && (
              <Col lg={6}>
                <img
                  src={item.galleryThirdImage.url}
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            )}

            {item.galleryFourthImage ? (
              <Col lg={6} className="mt-4 mt-lg-0">
                <img
                  src={item.galleryFourthImage.url}
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            ) : null}
          </Row>
        ) : null}

        {item.galleryDescription ? (
          <Row className="mt-5">
            <Col>{item.galleryDescription}</Col>
          </Row>
        ) : null}
      </ArticleContent>
    </StyledContainer>
  );
}

export { ItemArticle };
