import styled from "styled-components";

const StyledCloseButton = styled.button`
  position: absolute;
  bottom: 28px;
  background: #000000;
  border: none;
  border-radius: 25px;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 30px;
  box-shadow: 0px 0px 5px #6a6a6a;

  @media (max-width: 1280px) {
    bottom: 18px;
  }

  @media (max-width: 991px) {
    bottom: 38px;
  }
`;

const StyledContainer = styled.div`
  padding: 100px 160px;
  overflow: ${(props) => (props.isArticle ? "auto" : "hidden")};

  @media (max-width: 1280px) {
    padding: 80px 120px;
  }

  @media (max-width: 991px) {
    padding: 40px 40px 120px 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 10px 120px 10px;
  }

  iframe {
    cursor: pointer;

    body {
      cursor: pointer;
    }
  }
`;

const ArticleContent = styled.div`
  background-color: #ffffff;
  cursor: auto;
  padding: 40px;

  @media (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .dark & {
    background-color: #1c1c1c;
  }

  .section-title {
    &.additional-accordion {
      padding-top: 1rem;
    }
    @media (max-width: 1200px) {
      font-size: 2rem;
      font-weight: 400;
    }

    @media (max-width: 576px) {
      font-size: 1.5rem;
    }
  }

  .accordion-item {
    background-color: transparent;

    .dark & {
      border-color: #424242;
    }

    .accordion-button {
      color: #1c1c1c;
      background-color: transparent;
      padding-right: 0;
      padding-left: 0;
      font-size: 1.25rem;

      @media (max-width: 576px) {
        font-size: 1rem;
      }

      &:focus {
        box-shadow: none;
      }

      .dark & {
        color: #ffffff;
      }

      &:not(.collapsed) {
        color: #1c1c1c;

        background-color: transparent;

        .dark & {
          box-shadow: inset 0 -1px 0 rgb(255 255 255 / 13%);
          color: #ffffff;
        }
      }

      &::after {
        background-image: none;
        background-color: #424242;
        -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }

    .accordion-body {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const StyledImage = styled.img`
  opacity: 0;
  transition: all 0.5s ease;

  &.loaded {
    opacity: 1;
  }
`;

const StyledLoader = styled.div`
  width: 300px;
  height: 300px;
  position: absolute;
  opacity: 1;
  transition: all 0.5s ease;

  &.loaded {
    opacity: 0;
    z-index: 0 !important;
  }
`;

const StyledVideoPlayer = styled.div`
  width: 100%;
  opacity: 0;
  transition: all 0.5s ease;

  &.loaded {
    opacity: 1;
  }

  > div {
    padding-top: calc(100vh - 200px) !important;

    @media (max-width: 1280px) {
      padding-top: calc(100vh - 160px) !important;
    }
  }
`;

export {
  StyledCloseButton,
  StyledContainer,
  ArticleContent,
  StyledImage,
  StyledLoader,
  StyledVideoPlayer,
};
