import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { StyledFooter, StyledGetInTouch } from "./Footer.styled";
import { ContactModal } from "../contact-form/ContactModal";

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const backVariants = {
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 0, ...transition } },
};

function Footer() {
  const [showContactForm, setShowContactForm] = useState(false);

  const location = useLocation();

  return (
    <>
      <StyledFooter
        className="mt-auto pt-5"
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <motion.div className="container" variants={backVariants}>
          {!location.pathname.includes("contact") && (
            <StyledGetInTouch
              onClick={() => setShowContactForm(!showContactForm)}
              className="h1 main-title text-decoration-none"
            >
              Get in touch
            </StyledGetInTouch>
          )}

          <motion.div className="d-flex flex-column-reverse flex-lg-row justify-content-between mt-5">
            <span className="copyright text-center text-lg-start">
              2021 © SEESHARPSTUDIO
            </span>

            <ul className="footer-links list-unstyled list-inline text-center text-lg-start">
              <li className="list-inline-item px-2 px-sm-3">
                <a
                  href="https://www.facebook.com/SeeSharpStudio/"
                  target="_blank"
                  className="text-decoration-none"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li className="list-inline-item px-2 px-sm-3">
                <a
                  href="https://www.instagram.com/seesharpstudio/"
                  target="_blank"
                  className="text-decoration-none"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li className="list-inline-item px-2 px-sm-3">
                <NavLink to="/privacy-policy" className="text-decoration-none">
                  Privacy Policy
                </NavLink>
              </li>
            </ul>
          </motion.div>
        </motion.div>
      </StyledFooter>

      <ContactModal show={showContactForm} setShow={setShowContactForm} />
    </>
  );
}

export { Footer };
