import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Backdrop from "../backdrop/Backdrop";
import { ModalStyled } from "./Modal.styled";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.3,
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const Modal = ({ children, handleClose, dominantColor }) => {
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        if (locationKeys[1] !== location.key) {
          setLocationKeys((keys) => [location.key, ...keys]);

          handleClose();
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <Backdrop onClick={handleClose} dominantColor={dominantColor}>
      <ModalStyled
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {children}
      </ModalStyled>
    </Backdrop>
  );
};

export default Modal;
