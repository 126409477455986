import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Nav, PrivateRoute, Alert } from "./_components";

import { accountService } from "../_services";

import { Login } from "./auth/Login";
import { Register } from "./auth/Register";
import { VerifyEmail } from "./auth/VerifyEmail";
import { ForgotPassword } from "./auth/ForgotPassword";
import { ResetPassword } from "./auth/ResetPassword";
import { Pages, Media } from "./pages";

import { fetchMedia } from "../store/actions/mediaActions";
import { fetchPagesData } from "../store/actions/pageItemsActions";

function AdminContainer({ history, match }) {
  const { path } = match;
  const [canGoNext, setCanGoNext] = useState(false);
  const [user, setUser] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    accountService.refreshToken().finally(() => {
      setCanGoNext(true);
    });
  }, []);

  useEffect(() => {
    // redirect to home if already logged in
    dispatch(fetchMedia());
    dispatch(fetchPagesData());
    history.push("/admin/pages");
  }, [canGoNext]);

  useEffect(() => {
    const subscription = accountService.user.subscribe((userData) =>
      setUser(userData)
    );
    return subscription.unsubscribe;
  }, []);

  return (
    <>
      <Nav />
      <Alert />

      <Switch>
        <Route path={`${path}/login`} component={Login} />
        <Route path={`${path}/register`} component={Register} />
        <Route path={`${path}/verify-email`} component={VerifyEmail} />
        <Route path={`${path}/forgot-password`} component={ForgotPassword} />
        <Route path={`${path}/reset-password`} component={ResetPassword} />

        <PrivateRoute path={`${path}/media`} component={Media} />
        <PrivateRoute path={`${path}/pages`} component={Pages} />
      </Switch>
    </>
  );
}

export { AdminContainer };
