import React from "react";
import { motion } from "framer-motion";
import { Services } from "../_components/services/Services";
import { Clients } from "../_components/clients/Clients";
import "./style.scss";

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const backVariants = {
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 0, ...transition } },
};

function About() {
  return (
    <div className="about-us pt-4 pt-lg-4 pb-4">
      <motion.div
        className="container"
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <motion.h1 variants={backVariants} className="mt-0 mt-lg-4">
          This creative studio is driven by passion, expertise and high-end
          technology.
        </motion.h1>

        <motion.div variants={backVariants} className="row mt-4 mt-lg-5">
          <motion.div
            className="col-xl-6 col-lg-8 col-md-12 mb-3 description"
            variants={backVariants}
          >
            <p>
              Our team uses diverse perspectives to create world-class digital
              experiences that inspire people and grow brands.
            </p>
            <p>
              We focus on crafting unique stories that engage your audience and
              connect you to your customers.
            </p>
          </motion.div>
        </motion.div>

        {/* <Services variants={backVariants} /> */}
      </motion.div>

      <Clients variants={backVariants} />
    </div>
  );
}

export { About };
