import styled from "styled-components";

export const StyledHamburger = styled.button`
  border: none;
  background-color: transparent;
  height: 28px;
  width: 28px;
  padding: 0;
  cursor: pointer;

  @media (min-width: 992px) {
    display: none;
  }

  svg {
    &:focus {
      outline: none;
    }
  }
`;

export const StyledBurgerMenu = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 22px;
  height: 22px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  @media (min-width: 992px) {
    display: none;
  }

  div {
    width: 22px;
    height: 2px;
    background: #0d0c1d;
    border-radius: 10px;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    -webkit-transform-origin: 1px;

    .dark & {
      background-color: #ffffff;
    }

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      -webkit-transform: ${({ open }) =>
        open ? "rotate(45deg)" : "rotate(0)"};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(10px)" : "translateX(0)")};
      -webkit-transform: ${({ open }) =>
        open ? "translateX(10px)" : "translateX(0)"};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      -webkit-transform: ${({ open }) =>
        open ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
`;
