import { API_URL } from "../constants";
import { fetchWrapper } from "../_helpers";

// TODO: Use it from a config file
const baseUrl = `${API_URL}/contact`;

export const contactService = {
  sendMessage,
};

function sendMessage(payload) {
  return fetchWrapper.post(baseUrl, payload);
}
