import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

function PageContainer({ children }) {
  return (
    <Container>
      <Row>
        <Col sm={{ span: 8, offset: 2 }} className="mt-5">
          <Card className="m-3">{children}</Card>
        </Col>
      </Row>
    </Container>
  );
}

export { PageContainer };
