import styled from "styled-components";
import { motion } from "framer-motion";

const StyledClients = styled.div`
  background-color: #1c1c1c;
  color: #ffffff;
  margin-top: 20px;
`;

const StyledClientsLogos = styled(motion.div)`
  margin-bottom: 20px;
`;

export { StyledClients, StyledClientsLogos };
