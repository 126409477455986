import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, Button, FloatingLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SelectMediaModal } from "./index";
import { MediaItem } from "../index";
import { AccordionAddItem, AccordionsList } from "./_partials/index";
import {
  addPageItem,
  updatePageItem,
} from "../../../store/actions/pageItemsActions";
import { ITEM_TYPE, DESTINATION } from "../../../constants";
import { validateForm } from "../../../_helpers/validation";
import { ValidationMessage } from "../validation-message/ValidationMessage";

function UpsertItemModal({ show, setShow, activeItem }) {
  const [showMediaSelect, setShowMediaSelect] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [mediaObject, setMediaObject] = useState({});
  const [itemType, setItemType] = useState("");
  const [destination, setDestination] = useState(["home"]);
  const [articleFields, setArticleFields] = useState({});
  const [accordions, setAccordions] = useState([]);
  const [error, setError] = useState(false);

  const { data } = useSelector((state) => state.pageItems);

  const dispatch = useDispatch();

  useEffect(() => {
    if (activeItem) {
      setItemType(activeItem.itemType);
      setDestination(activeItem.destination);
      setMediaObject({
        image: activeItem.image,
        thumb: activeItem.thumb,
        galleryMainImage: activeItem.galleryMainImage,
        galleryFirstImage: activeItem.galleryFirstImage,
        gallerySecondImage: activeItem.gallerySecondImage,
        galleryThirdImage: activeItem.galleryThirdImage,
        galleryFourthImage: activeItem.galleryFourthImage,
      });
      setArticleFields({
        friendlyURL: activeItem.friendlyURL,
        video: activeItem.video,
        videoDominantColor: activeItem.videoDominantColor,
        videoPreview: activeItem.videoPreview,
        title: activeItem.title,
        shortDescription: activeItem.shortDescription,
        services: activeItem.services,
        additionalDescription: activeItem.additionalDescription,
        description: activeItem.description,
        additionalAccordion: activeItem.additionalAccordion,
        galleryDescription: activeItem.galleryDescription,
        galleryMainVideo: activeItem.galleryMainVideo,
        videoAutoPlay: activeItem.videoAutoPlay,
        videoHideControls: activeItem.videoHideControls,
        videoEnableLoop: activeItem.videoEnableLoop,
        galleryMainImageAutoPlay: activeItem.galleryMainImageAutoPlay,
        galleryMainImageHideControls: activeItem.galleryMainImageHideControls,
        galleryMainImageEnableLoop: activeItem.galleryMainImageEnableLoop,
      });
      setAccordions(activeItem.accordions);
    }
  }, [activeItem]);

  const handleTypeChange = (e) => {
    setItemType(e.target.value);
  };

  const handleDestinationChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      if (destination.indexOf(value) < 0) {
        setDestination([...destination, value]);
      }
    } else {
      setDestination(destination.filter((item) => item !== value));
    }
  };

  const handleVideoOptions = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    const obj = {};
    obj[value] = checked;

    setArticleFields({ ...articleFields, ...obj });
  };

  const handleArticleFields = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    const obj = {};
    obj[key] = value;

    setArticleFields({ ...articleFields, ...obj });
  };

  const handleMediaSelect = (type) => {
    setMediaType(type);
    setShowMediaSelect(true);
  };

  const handleModalHide = () => {
    setMediaType(null);
    setMediaObject({});
    setItemType("");
    setDestination(["home"]);
    setArticleFields({});

    setShow(false);
  };

  const saveAccordion = (payload) => {
    setAccordions([...accordions, payload]);
  };

  const deleteAccordion = (index) => {
    const updatedAccordions = [...accordions];
    updatedAccordions.splice(index, 1);

    setAccordions(updatedAccordions);
  };

  const setIndex = () => {
    if (activeItem) {
      return activeItem.index;
    }

    const count = {};

    destination.map((entry) => (count[entry] = 0));

    data.map((item) => {
      item.destination.map((itemDestination) => {
        if (destination.includes(itemDestination)) {
          count[itemDestination]++;
        }
      });
    });

    return count;
  };

  const removeHandler = (item) => {
    const mediaItem = {};
    mediaItem[item] = null;

    setMediaObject({
      ...mediaObject,
      ...mediaItem,
    });
  };

  const handleSubmit = async () => {
    const index = setIndex();

    const payload = {
      ...articleFields,
      ...mediaObject,
      accordions,
      itemType,
      destination,
      index,
    };

    const hasError = validateForm(itemType, payload);

    setError(hasError);

    if (hasError) {
      return;
    }

    if (activeItem) {
      await dispatch(updatePageItem({ ...payload, id: activeItem.id }));
    } else {
      await dispatch(addPageItem(payload));
    }

    handleModalHide();
  };

  return (
    <>
      <Modal
        className="add-items-modal"
        show={show}
        fullscreen={true}
        onHide={handleModalHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="6">
              <FloatingLabel
                controlId="article-type"
                label="Works with selects"
              >
                <Form.Select onChange={handleTypeChange} value={itemType}>
                  <option>select type ...</option>
                  <option value={ITEM_TYPE.IMAGE}>Image</option>
                  <option value={ITEM_TYPE.VIDEO}>Video</option>
                  <option value={ITEM_TYPE.ARTICLE}>Article</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>

          {itemType ? (
            <>
              <hr />
              <Row>
                <Col md="6">
                  <FloatingLabel controlId="friendly-url" label="Friendly URL">
                    <Form.Control
                      type="text"
                      name="friendlyURL"
                      size="sm"
                      onChange={handleArticleFields}
                      value={articleFields["friendlyURL"]}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <hr />
              {/* TODO: Use a property for this check */}
              {itemType === ITEM_TYPE.IMAGE ||
              itemType === ITEM_TYPE.ARTICLE ? (
                <>
                  <div className="select-media">
                    <Button
                      size="sm"
                      onClick={() => handleMediaSelect("image")}
                    >
                      Select Image
                    </Button>

                    {mediaObject.image ? (
                      <MediaItem
                        item={mediaObject.image}
                        removeText="Remove"
                        removeHandler={() => removeHandler("image")}
                      />
                    ) : null}
                  </div>
                  <ValidationMessage
                    error={error && !mediaObject.image}
                    message="Please select image"
                  />
                </>
              ) : null}

              <div>
                <div className="mt-3 select-media">
                  <Button size="sm" onClick={() => handleMediaSelect("thumb")}>
                    Select Thumb
                  </Button>

                  {mediaObject.thumb ? (
                    <MediaItem
                      item={mediaObject.thumb}
                      removeText="Remove"
                      removeHandler={() => removeHandler("thumb")}
                    />
                  ) : null}
                </div>

                <ValidationMessage
                  error={error && !mediaObject.thumb}
                  message="Please select thumb"
                />
              </div>

              {/* TODO: Use a property for this check */}
              {itemType === ITEM_TYPE.VIDEO ||
              itemType === ITEM_TYPE.ARTICLE ? (
                <>
                  <Row>
                    <Col md="6" className="mt-3">
                      <FloatingLabel
                        controlId="article-title"
                        label="Video preview URL"
                      >
                        <Form.Control
                          type="text"
                          name="videoPreview"
                          size="sm"
                          onChange={handleArticleFields}
                          value={articleFields["videoPreview"]}
                        />
                      </FloatingLabel>

                      <ValidationMessage
                        error={error && !articleFields["videoPreview"]}
                        message="Enter a valid URL"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mt-3">
                      <FloatingLabel
                        controlId="article-title"
                        label="Video URL"
                      >
                        <Form.Control
                          type="text"
                          name="video"
                          size="sm"
                          onChange={handleArticleFields}
                          value={articleFields["video"]}
                        />
                      </FloatingLabel>
                      <ValidationMessage
                        error={error && !articleFields["video"]}
                        message="Enter a valid URL"
                      />
                    </Col>

                    <Col className="mt-3 p-2">
                      <Form.Control
                        type="color"
                        name="videoDominantColor"
                        defaultValue={
                          articleFields["videoDominantColor"] || "#ffffff"
                        }
                        title="Choose your color"
                        onChange={handleArticleFields}
                        //onChange={handleDominantColor}
                      />
                    </Col>
                  </Row>

                  <Form.Check
                    type="switch"
                    value="videoAutoPlay"
                    name="videoAutoPlay"
                    label="Has autoplay"
                    className="mt-3"
                    checked={articleFields["videoAutoPlay"] === true}
                    onChange={handleVideoOptions}
                  />

                  <Form.Check
                    type="switch"
                    value="videoHideControls"
                    name="videoHideControls"
                    label="Hide controls"
                    checked={articleFields["videoHideControls"] === true}
                    onChange={handleVideoOptions}
                  />

                  <Form.Check
                    type="switch"
                    value="videoEnableLoop"
                    name="videoEnableLoop"
                    label="Enable loop"
                    checked={articleFields["videoEnableLoop"] === true}
                    onChange={handleVideoOptions}
                  />
                </>
              ) : null}

              <Row className="mt-3">
                <Col md="4">
                  <Form.Label>Select destination</Form.Label>
                  <Form.Check
                    type="switch"
                    id={DESTINATION.HOME}
                    value={DESTINATION.HOME}
                    label="Homepage"
                    checked={destination.some(
                      (item) => item === DESTINATION.HOME
                    )}
                    onChange={handleDestinationChange}
                  />
                  <Form.Check
                    type="switch"
                    id={DESTINATION.STILS}
                    value={DESTINATION.STILS}
                    label="Photos page"
                    checked={destination.some(
                      (item) => item === DESTINATION.STILS
                    )}
                    onChange={handleDestinationChange}
                  />
                  <Form.Check
                    type="switch"
                    id={DESTINATION.MOTION}
                    value={DESTINATION.MOTION}
                    label="Videos"
                    checked={destination.some(
                      (item) => item === DESTINATION.MOTION
                    )}
                    onChange={handleDestinationChange}
                  />
                </Col>
              </Row>
            </>
          ) : null}

          {/* TODO: Use a property for this check */}
          {itemType === ITEM_TYPE.VIDEO || itemType === ITEM_TYPE.ARTICLE ? (
            <>
              <hr className="my-4" />
              <Form>
                <Row>
                  <Col md="6">
                    <FloatingLabel controlId="article-title" label="Title">
                      <Form.Control
                        type="text"
                        name="title"
                        placeholder="enter title ..."
                        onChange={handleArticleFields}
                        value={articleFields["title"]}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <FloatingLabel
                      controlId="article-short-description"
                      label="Short Description"
                    >
                      <Form.Control
                        as="textarea"
                        name="shortDescription"
                        placeholder="short description ..."
                        style={{ height: "100px" }}
                        onChange={handleArticleFields}
                        value={articleFields["shortDescription"]}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <FloatingLabel
                      controlId="article-services"
                      label="Services"
                    >
                      <Form.Control
                        type="text"
                        name="services"
                        placeholder="services ..."
                        onChange={handleArticleFields}
                        value={articleFields["services"]}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Form>
            </>
          ) : null}

          {itemType === ITEM_TYPE.ARTICLE ? (
            <>
              <Form>
                <hr />

                <Row className="mt-3">
                  <Col md="6">
                    <FloatingLabel
                      controlId="additionalDescription"
                      label="Additional description"
                    >
                      <Form.Control
                        as="textarea"
                        name="additionalDescription"
                        placeholder="description ..."
                        style={{ height: "100px" }}
                        onChange={handleArticleFields}
                        value={articleFields["additionalDescription"]}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="6">
                    <FloatingLabel
                      controlId="articleDescription"
                      label="Description"
                    >
                      <Form.Control
                        as="textarea"
                        name="description"
                        placeholder="description ..."
                        style={{ height: "100px" }}
                        onChange={handleArticleFields}
                        value={articleFields["description"]}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <hr />

                <div className="mt-5">
                  <h5>Accordions</h5>

                  <Row className="mt-3">
                    <Col md="6">
                      <FloatingLabel
                        controlId="additionalAccordion"
                        label="Additional accordion"
                      >
                        <Form.Control
                          as="textarea"
                          name="additionalAccordion"
                          placeholder="description ..."
                          style={{ height: "100px" }}
                          onChange={handleArticleFields}
                          value={articleFields["additionalAccordion"]}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>

                  <AccordionsList
                    deleteAccordion={deleteAccordion}
                    accordions={accordions}
                  />

                  <AccordionAddItem saveAccordion={saveAccordion} />
                </div>

                <hr />

                <div className="mt-5">
                  <h5>Gallery</h5>

                  <div className="select-media">
                    <Button
                      size="sm"
                      onClick={() => handleMediaSelect("galleryMainImage")}
                    >
                      Select Main Image
                    </Button>

                    {mediaObject.galleryMainImage ? (
                      <MediaItem
                        item={mediaObject.galleryMainImage}
                        removeText="Remove"
                        removeHandler={() => removeHandler("galleryMainImage")}
                      />
                    ) : null}
                  </div>

                  <Row>
                    <Col md="6" className="mt-3">
                      <FloatingLabel
                        controlId="article-title"
                        label="Video URL"
                      >
                        <Form.Control
                          type="text"
                          name="galleryMainVideo"
                          size="sm"
                          onChange={handleArticleFields}
                          value={articleFields["galleryMainVideo"]}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>

                  <div className="mt-3">
                    <Form.Check
                      type="switch"
                      value="galleryMainImageAutoPlay"
                      name="galleryMainImageAutoPlay"
                      label="Has autoplay"
                      checked={
                        articleFields["galleryMainImageAutoPlay"] === true
                      }
                      onChange={handleVideoOptions}
                    />

                    <Form.Check
                      type="switch"
                      value="galleryMainImageHideControls"
                      name="galleryMainImageHideControls"
                      label="Hide controls"
                      checked={
                        articleFields["galleryMainImageHideControls"] === true
                      }
                      onChange={handleVideoOptions}
                    />

                    <Form.Check
                      type="switch"
                      value="galleryMainImageEnableLoop"
                      name="galleryMainImageEnableLoop"
                      label="Enable loop"
                      checked={
                        articleFields["galleryMainImageEnableLoop"] === true
                      }
                      onChange={handleVideoOptions}
                    />
                  </div>

                  <div className="select-media mt-5">
                    <Button
                      size="sm"
                      onClick={() => handleMediaSelect("galleryFirstImage")}
                    >
                      Select Gallery 1st Image
                    </Button>

                    {mediaObject.galleryFirstImage ? (
                      <MediaItem
                        item={mediaObject.galleryFirstImage}
                        removeText="Remove"
                        removeHandler={() => removeHandler("galleryFirstImage")}
                      />
                    ) : null}
                  </div>

                  <div className="select-media mt-3">
                    <Button
                      size="sm"
                      onClick={() => handleMediaSelect("gallerySecondImage")}
                    >
                      Select Gallery 2nd Image
                    </Button>

                    {mediaObject.gallerySecondImage ? (
                      <MediaItem
                        item={mediaObject.gallerySecondImage}
                        removeText="Remove"
                        removeHandler={() =>
                          removeHandler("gallerySecondImage")
                        }
                      />
                    ) : null}
                  </div>

                  <div className="select-media mt-3">
                    <Button
                      size="sm"
                      onClick={() => handleMediaSelect("galleryThirdImage")}
                    >
                      Select Gallery 3rd Image
                    </Button>

                    {mediaObject.galleryThirdImage ? (
                      <MediaItem
                        item={mediaObject.galleryThirdImage}
                        removeText="Remove"
                        removeHandler={() => removeHandler("galleryThirdImage")}
                      />
                    ) : null}
                  </div>

                  <div className="select-media mt-3">
                    <Button
                      size="sm"
                      onClick={() => handleMediaSelect("galleryFourthImage")}
                    >
                      Select Gallery 4th Image
                    </Button>

                    {mediaObject.galleryFourthImage ? (
                      <MediaItem
                        item={mediaObject.galleryFourthImage}
                        removeText="Remove"
                        removeHandler={() =>
                          removeHandler("galleryFourthImage")
                        }
                      />
                    ) : null}
                  </div>

                  <Row className="mt-3">
                    <Col md="6">
                      <FloatingLabel
                        controlId="galleryDescription"
                        label="Gallery description"
                      >
                        <Form.Control
                          as="textarea"
                          name="galleryDescription"
                          placeholder="description ..."
                          style={{ height: "100px" }}
                          onChange={handleArticleFields}
                          value={articleFields["galleryDescription"]}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </div>
              </Form>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} size="sm">
            {`${activeItem ? "Update" : "Add"} Item`}
          </Button>
        </Modal.Footer>
      </Modal>

      <SelectMediaModal
        show={showMediaSelect}
        setShow={setShowMediaSelect}
        mediaType={mediaType}
        mediaObject={mediaObject}
        setMediaObject={setMediaObject}
      />
    </>
  );
}

export { UpsertItemModal };
