import React, { useState, useMemo, useEffect } from "react";
import Masonry from "react-masonry-css";
import { motion } from "framer-motion";
import { sortItems } from "../../_helpers/functions";
import { LazyItem } from "./LazyItem";
import { ItemDetails } from "../item-details/ItemDetails";
import { DESTINATION } from "../../constants";

import "./style.scss";

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: { scale: 0.9, opacity: 0, transition },
};

const frameVariants = {
  hover: { scale: 0.95 },
};

function ListItems({ data, area, friendlyURL }) {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (friendlyURL) {
      const activeItem = data
        .filter((item) => item.destination.includes(area))
        .find(
          (item) => item.id === friendlyURL || item.friendlyURL === friendlyURL
        );

      if (activeItem) {
        setShowDetails(true);
        setSelectedItem(activeItem);
      }
    }
  }, []);

  const items = useMemo(
    () =>
      data
        .filter((item) => item.destination.includes(area))
        .sort(sortItems(area)),
    [data]
  );

  const handleShowDetails = () => {
    setShowDetails(false);
    setSelectedItem(null);
  };

  return (
    <>
      {area === DESTINATION.MOTION ? (
        <motion.div className="row list-items">
          {items.map((item, i) => (
            <motion.div
              className="col-xxl-4 col-xl-4 col-lg-6"
              initial="initial"
              animate="enter"
              exit={{ y: 80, opacity: 0, transition }}
              key={i}
            >
              <motion.div className="thumbnail" variants={thumbnailVariants}>
                <motion.div
                  className="frame"
                  whileHover="hover"
                  variants={frameVariants}
                  transition={transition}
                >
                  <LazyItem
                    item={item}
                    key={i}
                    setSelectedItem={setSelectedItem}
                    setShowDetails={setShowDetails}
                    area={area}
                  />
                </motion.div>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <Masonry
          breakpointCols={{
            default: 3,
            991: 2,
          }}
          className="my-masonry-grid list-items"
          columnClassName="my-masonry-grid_column"
        >
          {items.map((item, i) => (
            <motion.div
              className="thumbnail"
              variants={thumbnailVariants}
              key={i}
            >
              <motion.div
                className="frame"
                whileHover="hover"
                variants={frameVariants}
                transition={transition}
              >
                <LazyItem
                  item={item}
                  key={i}
                  setSelectedItem={setSelectedItem}
                  setShowDetails={setShowDetails}
                />
              </motion.div>
            </motion.div>
          ))}
        </Masonry>
      )}

      <ItemDetails
        show={showDetails}
        setShow={handleShowDetails}
        item={selectedItem}
        area={area}
        friendlyURL={friendlyURL}
      />
    </>
  );
}

export { ListItems };
