import React from "react";
import classNames from "classnames";
import { useWindowSize } from "../../hooks/window-size";

const ContentArea = ({ children }) => {
  const size = useWindowSize();

  const containerClasses = classNames("px-2 py-4", {
    container: size.width > 576,
  });

  return <div className={containerClasses}>{children}</div>;
};

export { ContentArea };
