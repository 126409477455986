import styled from "styled-components";
import { Form } from "react-bootstrap";
import CloseIcon from "../../assets/close-icon_old.svg";
import CloseIconDark from "../../assets/close-icon-dark_old.svg";

const StyledForm = styled(Form)`
  input:not(.form-check-input),
  textarea {
    border: none;
    border-bottom: 1px solid #d2d2d2;
    border-radius: 0;
    background: none;
    color: #1c1c1c;
    padding: 0;
    font-weight: 100;

    &:hover {
      border-color: #000000;
    }

    .dark & {
      color: #ffffff;
      border-color: #818181;

      &:hover {
        border-color: #ffffff;
      }
    }

    &:focus {
      background: none;
      border-color: #d2d2d2;
    }

    &::placeholder {
      color: #d2d2d2;
      font-size: 1rem;
    }
  }

  textarea {
    height: 100px;
  }

  .terms {
    input {
      border-radius: 0;

      &:not(:checked) {
        background-color: transparent;

        .dark & {
          border-color: #ffffff;
        }
      }
    }

    label {
      font-size: 0.8rem;
    }
  }

  button {
    padding: 0;
    color: #a4a4a4;
    text-decoration: none;
    font-weight: 100;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #a4a4a4;
      position: absolute;
      bottom: -2px;
      left: 0;
    }

    &:hover {
      color: #5f5f5f;

      &:after {
        background-color: #5f5f5f;
      }
    }
  }

  .invalid-feedback {
    position: absolute;
    font-size: 0.75rem;
  }
`;

const StyledContactModalContainer = styled.div`
  cursor: pointer;
  background-color: #ffffff;
  width: 90%;
  max-width: 600px;
  padding: 40px 50px 40px;
  position: relative;

  .dark & {
    background-color: #1c1c1c;
  }
`;

const StyledCloseIcon = styled.button`
  background-color: transparent;
  background-image: url(${CloseIcon});
  background-size: contain;
  border: none;
  position: absolute;
  right: 45px;
  top: 45px;
  width: 20px;
  height: 20px;
  padding: 0;

  .dark & {
    background-image: url(${CloseIconDark});
  }
`;

export { StyledForm, StyledContactModalContainer, StyledCloseIcon };
