import styled from "styled-components";
import { motion } from "framer-motion";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
}

const BackdropStyled = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(${(props) => hexToRgb(props.dominantcolor)}, 0.9);
`;

export { BackdropStyled };
