import React from "react";
const utilContext = React.createContext();

export function UtilProvider(props) {
  const util = useProviderUtil();
  return (
    <utilContext.Provider value={util}>
      {props.children}
      </utilContext.Provider>
  );
}

export const useUtil = () => {
  return React.useContext(utilContext);
};

function useProviderUtil() {
const [pageLoaded, setPageLoaded] = React.useState(false)

const handleChangeState = () => {
    setPageLoaded(true)
}

  return {
    pageLoaded,
    handleChangeState
  };
}
