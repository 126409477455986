import React from "react";
// import { Form } from "react-bootstrap";
import { StyledValidationMessage } from "./ValidationMessage.styled";

function ValidationMessage({ error, message }) {
  return (
    <StyledValidationMessage
      type="invalid"
      style={{ display: error ? "block" : "none" }}
    >
      {message}
    </StyledValidationMessage>
  );
}

export { ValidationMessage };
