import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";

const StyledList = styled.ul`
  font-size: 1rem;

  li {
    text-align: left;
  }
`;

const StyledCarousel = styled(Carousel)`
  .carousel {
    padding-bottom: 40px;

    .control-dots {
      bottom: 0;
      margin: 0;

      li {
        border-radius: 0;
        box-shadow: none;
        background: #1c1c1c;
        width: 55px;
        height: 2px;

        .dark & {
          background-color: #ffffff;
        }
      }
    }
  }
`;

export { StyledList, StyledCarousel };
