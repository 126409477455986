import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { CookiesProvider } from "react-cookie";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { mediaReducer } from "./store/reducers/mediaReducers";
import { pageItemsReducer } from "./store/reducers/pageItemsReducers";
import { ScrollToTop } from "./_components/scroll-to-top/ScrollToTop";

const rootReducer = combineReducers({
  media: mediaReducer,
  pageItems: pageItemsReducer,
});

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
      : (f) => f
  )
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <CookiesProvider>
          <ScrollToTop />
          <App />
        </CookiesProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
