import React from "react";
import { motion } from "framer-motion";
import { Row, Col, Image } from "react-bootstrap";
import { StyledClients, StyledClientsLogos } from "./Clients.styled";
import * as clientLogos from "../../assets/clients";

const Clients = ({ variants }) => {
  return (
    <StyledClients>
      <motion.div
        className="container"
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <motion.div variants={variants} className="row pt-3">
          <motion.div className="col-12" variants={variants}>
            <h2 className="h1 mt-3">Trusted by</h2>
          </motion.div>

          <StyledClientsLogos
            className="col-xl-10 offset-xl-1"
            variants={variants}
          >
            <Row>
              {Object.values(clientLogos).map((client, idx) => (
                <Col lg={2} md={3} sm={4} xs={4} key={idx}>
                  <Image
                    src={client}
                    alt={Object.keys(clientLogos)[idx]}
                    className="img-fluid"
                  />
                </Col>
              ))}
              {/* <Col xl={2} sm={4}>
            <Image src={Amz} className="img-fluid" />
          </Col> */}
            </Row>
          </StyledClientsLogos>
        </motion.div>
      </motion.div>
    </StyledClients>
  );
};

export { Clients };
