import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Form, Image } from "react-bootstrap";
import {
  StyledNavMenuMenu,
  StyledDarkSwitch,
  StyledMenuLabel,
} from "./NavMenu.styled";
import { useWindowSize } from "../../hooks/window-size";
import DarkModeImage from "../../assets/dark-switch.svg";

const NavMenu = ({
  openMobileMenu,
  setOpenMobileMenu,
  darkMode,
  toggleDarkMode,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const size = useWindowSize();

  const location = useLocation();

  useEffect(() => {
    if (size.width > 991) {
      setOpenMobileMenu(false);
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size]);

  useEffect(() => {
    setOpenMobileMenu(false);
  }, [location]);

  const menuClassNames = classNames("pe-0 pe-lg-3", {
    "mobile-menu": isMobile,
    "is-opened": openMobileMenu,
  });

  const menuContainerClassNames = classNames("d-flex", {
    "align-items-center": !isMobile,
    "container flex-column": isMobile,
  });

  return (
    <StyledNavMenuMenu className={menuClassNames}>
      <div className={menuContainerClassNames}>
        <StyledMenuLabel className="d-lg-none">Menu</StyledMenuLabel>

        <NavLink to="/" exact={true}>
          Home
        </NavLink>
        <NavLink to="/stills">Stills</NavLink>
        <NavLink to="/motion">Motion</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/contact">Contact</NavLink>

        <StyledDarkSwitch>
          <Form.Check
            type="switch"
            checked={darkMode}
            onChange={toggleDarkMode}
            className="size-md"
          />
          <Image src={DarkModeImage} />
        </StyledDarkSwitch>
      </div>
    </StyledNavMenuMenu>
  );
};

export { NavMenu };
