import React, { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";
import lottie from "lottie-web/build/player/lottie_light";
import classNames from "classnames";
import {
  StyledContainer,
  StyledVideoPlayer,
  StyledLoader,
} from "../ItemDetails.styled";
import animationData from "./animationData.json";

function ItemVideo({ item }) {
  const animationContainer = useRef(null);
  const anim = useRef(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData,
      });
    }

    const player = new Player("test-player", {
      url: item.video,
      loop: item.videoEnableLoop,
      controls: !item.videoHideControls,
      responsive: true,
      muted: true,
    });

    player.setMuted();

    if (item.videoAutoPlay) {
      player.play();
    }

    player.ready().then(() => {
      setLoaded(true);
    });

    if (animationContainer.current) {
      return () => {
        anim.current?.destroy();
      };
    }
  }, []);

  const videoClass = classNames("mw-100 mh-100", {
    loaded,
  });

  const loaderClass = classNames({
    loaded,
  });

  return (
    <StyledContainer className="h-100 w-100 d-flex align-items-center justify-content-center">
      <StyledLoader className={loaderClass} ref={animationContainer} />

      <StyledVideoPlayer className={videoClass} id="test-player" />
    </StyledContainer>
  );
}

export { ItemVideo };
