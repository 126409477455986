import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";

import { accountService } from "../../_services";
import { clearMedia } from "../../store/actions/mediaActions";

function Nav() {
  const [user, setUser] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const subscription = accountService.user.subscribe((x) => setUser(x));
    return subscription.unsubscribe;
  }, []);

  const handleLogout = async () => {
    await dispatch(clearMedia());
    accountService.logout();
  };

  // only show nav when logged in
  if (!user) return null;

  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <div className="navbar-nav">
          <NavLink to="/admin/pages" className="nav-item nav-link">
            Pages
          </NavLink>

          <NavLink to="/admin/media" className="nav-item nav-link">
            Media
          </NavLink>

          <Button size="sm" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </nav>
    </div>
  );
}

export { Nav };
