import styled from "styled-components";
import { motion } from "framer-motion";

const PageHeaderStyled = styled(motion.div)`
  .modal-open & {
    filter: blur(7px);
  }
`;

export { PageHeaderStyled };
