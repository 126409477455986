import React from "react";
import { Figure } from "react-bootstrap";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

const Item = styled.div`
  border: ${({ canDrag }) => (!canDrag ? "1px solid grey" : "1px solid blue")};
  margin-bottom: 8px;
  background-color: #ffffff;
  padding: 8px;
`;

function PageItem({ index, pageItem, canDrag, setActiveItem }) {
  const handleClick = (cardId) => {
    if (!canDrag) {
      setActiveItem(cardId);
    }
  };

  return (
    <Draggable
      draggableId={pageItem.id}
      index={index}
      isDragDisabled={!canDrag}
    >
      {(provided) => (
        <Item
          className="mb-2"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => handleClick(pageItem.id)}
          canDrag={canDrag}
        >
          <Figure.Image
            width={100}
            height={100}
            src={pageItem.thumb.url}
            className="p-1 m-0 me-3"
          />
          {pageItem.itemType} - {pageItem.id}
        </Item>
      )}
    </Draggable>
  );
}

export { PageItem };
