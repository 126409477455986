import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { PageHeader } from "../_components/page-header/PageHeader";
import { ContentArea } from "../_components/content-area/ContentArea";
import { fetchPagesData } from "../store/actions/pageItemsActions";
import { ListItems } from "../_components/list-items/ListItems";
import { DESTINATION } from "../constants";

import { PageLoader } from "../_components/page-loader";
import { useUtil } from "../_components/page-loader/context";

function Motion() {
  const { data } = useSelector((state) => state.pageItems);

  const { friendlyURL } = useParams();

  const dispatch = useDispatch();

  const { pageLoaded, handleChangeState } = useUtil();

  useEffect(() => {
    if (data.length > 0) {
      handleChangeState();
    }
    dispatch(fetchPagesData());
  }, [data]);

  return (
    <React.Fragment>
      {pageLoaded ? (
        <React.Fragment>
          <Container>
            <PageHeader title="Motion">
              We create videos that inspire and help our customers reach their
              audience in ways that no other mediums can provide. Utilizing the
              best crew, equipment and technology, we push the limits of
              production, making sure our products exceed any expectation.
            </PageHeader>
          </Container>
          <ContentArea>
            <ListItems
              data={data}
              area={DESTINATION.MOTION}
              friendlyURL={friendlyURL}
            />
          </ContentArea>
        </React.Fragment>
      ) : (
        <PageLoader />
      )}
    </React.Fragment>
  );
}

export { Motion };
