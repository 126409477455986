import React, { useEffect, useRef } from "react";
import { useState } from "react";
import classNames from "classnames";
import lottie from "lottie-web/build/player/lottie_light";
import {
  StyledContainer,
  StyledImage,
  StyledLoader,
} from "../ItemDetails.styled";
import animationData from "./animationData.json";

function ItemImage({ item, area }) {
  const animationContainer = useRef(null);
  const anim = useRef(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData,
      });

      return () => {
        anim.current?.destroy();
      };
    }
  }, []);

  const imageClass = classNames("mw-100 mh-100", {
    loaded,
  });

  const loaderClass = classNames({
    loaded,
  });

  return (
    <StyledContainer className="h-100 w-100 d-flex align-items-center justify-content-center">
      <StyledLoader className={loaderClass} ref={animationContainer} />

      <StyledImage
        src={item.image.url}
        className={imageClass}
        alt="..."
        onLoad={() => setLoaded(true)}
      />
    </StyledContainer>
  );
}

export { ItemImage };
