import * as ACTION from "../constants";

const initialState = {
  allImages: [],
  error: false,
};

export const mediaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION.CLEAR_MEDIA:
      return initialState;

    case ACTION.FETCH_MEDIA:
      return state;
    case ACTION.FETCH_MEDIA_SUCCESS:
      return {
        error: false,
        allImages: payload.data,
      };
    case ACTION.FETCH_MEDIA_FAIL:
      return {
        ...state,
        error: true,
      };

    case ACTION.ADD_MEDIA:
      return state;
    case ACTION.ADD_MEDIA_SUCCESS:
      return {
        error: false,
        allImages: [...state.allImages, payload],
      };
    case ACTION.ADD_MEDIA_FAIL:
      return {
        ...state,
        error: true,
      };

    case ACTION.UPDATE_MEDIA:
      return state;
    case ACTION.UPDATE_MEDIA_SUCCESS:
      state.allImages.forEach((item, index, allImages) => {
        if (item.name === payload.name) {
          allImages[index] = { ...allImages[index], ...payload };
        }
      });
      return state;
    case ACTION.UPDATE_MEDIA_FAIL:
      return {
        ...state,
        error: true,
      };

    case ACTION.DELETE_MEDIA:
      return state;
    case ACTION.DELETE_MEDIA_SUCCESS:
      return {
        error: false,
        allImages: state.allImages.filter((media) => media.id !== payload),
      };
    case ACTION.DELETE_MEDIA_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
