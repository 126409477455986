import React from "react";
import { motion } from "framer-motion";
import "./style.scss";

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const backVariants = {
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 0, ...transition } },
};

const PrivacyPolicy = () => {
  return (
    <div className="pt-4 pt-lg-4 pb-4">
      <motion.div
        className="container"
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <motion.h1 variants={backVariants} className="mt-0 mt-lg-4">
          Privacy Policy
        </motion.h1>

        <motion.div variants={backVariants} className="row mt-4 mt-lg-5">
          <motion.div
            className="col-xl-6 col-lg-8 col-md-12 mb-3 description"
            variants={backVariants}
          >
            <p>
              <strong>Lorem ipsum dolor sit amet</strong>
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              mollis tellus at sagittis tincidunt. Aenean faucibus erat
              tincidunt ultricies tempor. Mauris posuere metus euismod urna
              hendrerit ornare. Sed in magna sed elit molestie aliquet non sit
              amet arcu. Nunc consectetur neque in nunc viverra, ac commodo
              libero eleifend. Duis id enim non quam pellentesque lacinia sit
              amet eget urna. Nam lacinia, lorem at dictum placerat, nunc augue
              tempor nunc, quis fringilla sem nulla quis quam. Pellentesque
              elementum libero nec quam finibus vulputate. Suspendisse et
              volutpat lectus. Suspendisse quis porttitor ipsum. Pellentesque
              habitant morbi tristique senectus et netus et malesuada fames ac
              turpis egestas. Sed eu libero congue, porta dolor nec, tincidunt
              odio. Aliquam id odio eu nibh dictum pulvinar a eu sem. Duis
              vestibulum viverra felis eget interdum. Quisque pellentesque augue
              in odio venenatis tristique.
            </p>

            <p>
              Vestibulum ligula nisl, blandit sed condimentum nec, lacinia sit
              amet ex. In rhoncus lacus odio, ac hendrerit risus maximus sit
              amet. Pellentesque ut interdum sem. Nam sagittis, mauris a
              efficitur feugiat, lorem nunc euismod sem, a malesuada mauris mi
              non velit. Aenean ultricies diam orci, porttitor pretium magna
              maximus rhoncus. Duis consectetur enim efficitur sem consequat
              fermentum. Maecenas aliquam erat at tempus volutpat. Etiam mattis
              egestas erat vel elementum. Donec vestibulum, mi in vehicula
              ornare, lorem lectus vestibulum lorem, quis varius odio felis
              elementum nisl. Aliquam ac libero et risus gravida mollis ac ac
              ex. Fusce id lacus fermentum, luctus tellus a, egestas augue. In
              risus tellus, pharetra maximus rutrum a, efficitur vitae mauris.
              Nam fermentum dapibus orci, non scelerisque massa fringilla a.
              Nulla in facilisis urna.
            </p>

            <p>
              Sed a malesuada lectus. Quisque lobortis lectus et purus vehicula
              sagittis. Maecenas dictum ut eros ac aliquam. Nulla interdum
              dapibus quam ac ornare. Vestibulum semper, ante eget elementum
              suscipit, magna dui sagittis ligula, id dictum erat turpis eu
              nisi. Sed ut lorem vitae libero vulputate iaculis in eu metus.
              Etiam a pulvinar est, at cursus leo. Vestibulum scelerisque
              vulputate magna, quis rutrum purus facilisis ac. Aenean pharetra
              pellentesque faucibus. Sed rutrum diam ipsum, vel egestas eros
              accumsan sit amet. Nulla facilisi. Morbi eu pharetra erat. Sed
              eget augue velit. Donec nec purus vel dolor auctor maximus. Ut
              porta, justo nec condimentum maximus, risus leo vehicula tellus,
              ultrices finibus enim arcu quis elit.
            </p>

            <p>
              Nulla nibh lacus, auctor in elementum a, vehicula ut augue. Fusce
              posuere nunc sed ligula pharetra pharetra. Pellentesque sed nisl
              leo. Sed dignissim nulla quam, non mattis ante interdum fermentum.
              Aenean vitae faucibus elit, blandit ultrices justo. Mauris mollis
              ac leo non tempor. Etiam in arcu nisi. Etiam a rutrum metus. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos. Nunc faucibus tincidunt risus, et lacinia
              tortor rhoncus quis. Integer vel ex vel mauris tristique blandit
              in vitae est.
            </p>

            <p>
              Sed maximus nisl at nunc ornare, eu porta risus tristique. In
              tempor nec lorem vitae efficitur. In scelerisque egestas blandit.
              Aenean bibendum tincidunt metus id cursus. Sed vitae tellus
              consequat, pharetra sem quis, molestie arcu. Nulla nec gravida
              lectus. Vestibulum tincidunt luctus rutrum. Integer fringilla
              neque eu pellentesque posuere. Donec non nibh rutrum, pretium
              dolor sed, sodales diam. Morbi sagittis cursus dolor a tristique.
              Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
              posuere cubilia curae; Nullam vitae elementum nisi. Curabitur
              fermentum sit amet ipsum ut eleifend. Sed placerat nibh non ligula
              eleifend, sed efficitur urna malesuada. Praesent ac mauris diam.
            </p>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export { PrivacyPolicy };
