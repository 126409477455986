import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { Navbar } from "../../_components/navbar/Navbar";
import { Footer } from "../../_components/footer/Footer";
import { Home, Stils, Motion, About, Contact, PrivacyPolicy } from "../index";

import { PageLoader } from "../../_components/page-loader";
import { UtilProvider } from "../../_components/page-loader/context";

function Container() {
  return (
    <UtilProvider>
      <AnimatePresence exitBeforeEnter>
        <Navbar />

        <Route
          render={({ location }) => (
            <Switch location={location} key={location.pathname}>
              <Route exact path="/stills/:friendlyURL" component={Stils} />
              <Route exact path="/stills" component={Stils} />
              <Route exact path="/motion/:friendlyURL" component={Motion} />
              <Route exact path="/motion" component={Motion} />
              <Route exact path="/" component={Home} />
              <Route exact path="/:friendlyURL" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Redirect from="*" to="/" />
            </Switch>
          )}
        />

        <Footer />
      </AnimatePresence>
    </UtilProvider>
  );
}

export { Container };
