import { API_URL } from "../constants";
import { fetchWrapper } from "../_helpers";

// TODO: Use it from a config file
const baseUrl = `${API_URL}/items`;

export const pageItemService = {
  addItem,
  getItems,
  update,
  updatePosition,
};

function addItem(payload) {
  return fetchWrapper.post(baseUrl, payload);
}

function getItems() {
  return fetchWrapper.get(baseUrl);
}

function update(payload) {
  return fetchWrapper.put(`${baseUrl}/single/${payload.id}`, payload);
}

function updatePosition(payload) {
  return fetchWrapper.put(`${baseUrl}/update-position`, payload);
}
