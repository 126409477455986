import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import lottie from "lottie-web/build/player/lottie_light";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { ITEM_TYPE, DESTINATION } from "../../constants";
import { VideoPreview } from "./VideoPreview";
import PlayButton from "../../assets/play-button.svg";
import { ReactSVG } from "react-svg";
import { StyledLoader } from "../item-details/ItemDetails.styled";
import animationData from "../item-details/_partials/animationData.json";

function LazyItem({ item, area, setSelectedItem, setShowDetails, visible }) {
  const animationContainer = useRef(null);
  const anim = useRef(null);

  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData,
      });

      return () => {
        anim.current?.destroy();
      };
    }
  }, [inView]);

  const handleClick = () => {
    setShowDetails(true);
    setSelectedItem(item);
  };

  const shouldDisplayAsVideo =
    item.itemType === ITEM_TYPE.VIDEO ||
    area === DESTINATION.MOTION ||
    !!item.videoPreview;

  const shouldDisplayVideoDetails = area === DESTINATION.MOTION;

  const handleHover = () => {
    if (!isMobile && shouldDisplayAsVideo) {
      setShowVideoPreview(true);
    }
  };

  const handleMouseLeave = () => {
    setShowVideoPreview(false);
  };

  const classes = classNames("p-1 p-sm-2 item", {
    "in-viewport": inView,
  });

  const loaderClass = classNames({
    loaded,
  });

  const imageClass = classNames("position-absolute top-0 start-0", {
    loaded,
  });

  const textWrapperClasses = classNames("text-wrapper", {
    "in-viewport": inView,
  });

  const handleImageLoad = () => {
    setLoaded((prev) => !prev);
  };

  return (
    <>
      <div ref={ref} className={classes}>
        <>
          <div
            className="position-relative item-content"
            style={{ paddingTop: `${item.thumb.aspectRatio * 100}%` }}
            onMouseEnter={handleHover}
          >
            {inView && (
              <>
                <StyledLoader
                  className={loaderClass}
                  ref={animationContainer}
                  style={{
                    position: "absolute",
                    top: 0,
                    zIndex: 999999,
                    width: "100%",
                    height: "100%",
                  }}
                />
                <img
                  className={imageClass}
                  src={item.thumb.url}
                  style={{
                    width: "100%",
                    display: "block",
                    opacity: showVideoPreview ? 0 : 1,
                  }}
                  alt={item.itemType}
                  title={item.itemType}
                  onClick={handleClick}
                  // onLoad={() => setLoaded(true)}
                  onLoad={handleImageLoad}
                />

                {shouldDisplayAsVideo && (
                  <ReactSVG
                    src={PlayButton}
                    className="play-button position-absolute top-50 start-50 translate-middle"
                  />
                )}

                {shouldDisplayAsVideo && (
                  <VideoPreview
                    videoUrl={item.videoPreview}
                    handleMouseLeave={handleMouseLeave}
                    handleClick={handleClick}
                    canBeDisplayed={showVideoPreview && shouldDisplayAsVideo}
                  />
                )}
              </>
            )}
          </div>

          {shouldDisplayVideoDetails && (
            <div className={textWrapperClasses} onClick={handleClick}>
              {item.title && (
                <h4 className="item-title mt-3 mb-2">{item.title}</h4>
              )}
              {item.shortDescription && (
                <div className="item-description">{item.shortDescription}</div>
              )}
            </div>
          )}
        </>
      </div>
    </>
  );
}

export { LazyItem };
