import { BackdropStyled } from "./Backdrop.styled";

const Backdrop = ({ children, onClick, dominantColor }) => {
  return (
    <BackdropStyled
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      dominantcolor={dominantColor}
    >
      {children}
    </BackdropStyled>
  );
};

export default Backdrop;
