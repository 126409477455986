import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ItemsContainer } from "../_components/items/ItemsContainer";
import { UpsertItemModal } from "../_components/modals";
import { DESTINATION } from "../../constants";

function Pages() {
  const [showAddImagesModal, setShowAddImagesModal] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const { data } = useSelector((state) => state.pageItems);

  const handleAddImages = () => {
    setShowAddImagesModal(true);
  };

  const handleClick = (itemId) => {
    const selectedItem = data.find((item) => item.id === itemId);

    setActiveItem(selectedItem);
    setShowAddImagesModal(true);
  };

  return (
    <>
      <div className="p-4">
        <Container>
          <h1>Pages</h1>
          <Button
            variant="primary"
            size="sm"
            onClick={handleAddImages}
            className="mb-5"
          >
            Add items
          </Button>

          <ItemsContainer
            title="Home"
            area={DESTINATION.HOME}
            setActiveItem={handleClick}
          />

          <ItemsContainer
            title="Stils"
            area={DESTINATION.STILS}
            setActiveItem={handleClick}
          />

          <ItemsContainer
            title="Motion"
            area={DESTINATION.MOTION}
            setActiveItem={handleClick}
          />
        </Container>
      </div>

      <UpsertItemModal
        show={showAddImagesModal}
        setShow={setShowAddImagesModal}
        activeItem={activeItem}
      />
    </>
  );
}

export { Pages };
