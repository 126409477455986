import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { IconContext } from "react-icons";
import Modal from "../modal/Modal";
import { ContactForm } from "./ContactForm";
import {
  StyledContactModalContainer,
  StyledCloseIcon,
} from "./ContactForm.styled";

const ContactModal = ({ show, setShow }) => {
  useEffect(() => {
    if (show) {
      document.documentElement.classList.add("modal-open");
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    document.documentElement.classList.remove("modal-open");
  };

  return (
    <>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {show && (
          <Modal handleClose={handleClose}>
            <StyledContactModalContainer onClick={(e) => e.stopPropagation()}>
              <StyledCloseIcon onClick={handleClose} />
              <h2 className="pb-3">Get in touch</h2>
              <ContactForm />
            </StyledContactModalContainer>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export { ContactModal };
