export const BUCKET_URL = "https://storage.googleapis.com/see_sharp_bucket/";
export const API_URL = process.env.REACT_APP_API_END_POINT;

export const ITEM_TYPE = {
  IMAGE: "image",
  VIDEO: "video",
  ARTICLE: "article",
};

export const DESTINATION = {
  HOME: "home",
  STILS: "photos",
  MOTION: "videos",
};
