import { ITEM_TYPE } from "../constants";

export const validateForm = (itemType, payload) => {
  let error;

  switch (itemType) {
    case ITEM_TYPE.IMAGE:
      error = validateImageForm(payload);
      break;
    case ITEM_TYPE.VIDEO:
      error = validateVideoForm(payload);
      break;
    case ITEM_TYPE.ARTICLE:
      error = validateArticleForm(payload);
      break;
    default:
      error = false;
  }

  return error;
};

const validateImageForm = (payload) => {
  if (!payload.image || !payload.thumb) {
    return true;
  }

  return false;
};

const validateVideoForm = (payload) => {
  if (!payload.thumb || !payload.video || !payload.videoPreview) {
    return true;
  }

  return false;
};

const validateArticleForm = (payload) => {
  if (!payload.thumb) {
    return true;
  }

  if (!payload.image && !payload.video) {
    return true;
  }

  if (payload.video && !payload.videoPreview) {
    return true;
  }

  return false;
};
