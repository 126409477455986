import React from "react";
import { motion } from "framer-motion";
import { ContactForm } from "../_components/contact-form/ContactForm";

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const backVariants = {
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 0, ...transition } },
};

function Contact() {
  return (
    <div className="py-4">
      <motion.div
        className="contact-page container mt-3 mt-lg-6"
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <motion.div className="row" variants={backVariants}>
          <div className="col-lg-6 order-2 order-lg-1">
            <h1 className="d-none d-lg-block">Get in touch!</h1>
            <div className="row mt-6">
              <div className="col-lg-4">
                <h4>Address</h4>
              </div>
              <div className="col-lg-8">
                Jiului Street 101, 013212, Bucharest
              </div>
            </div>
            <div className="row mt-4 mt-lg-3">
              <div className="col-lg-4">
                <h4>E-mail</h4>
              </div>
              <div className="col-lg-8">
                <a href="mailto:office@seesharpstudio.com">
                  office(at)seesharpstudio.com
                </a>
              </div>
            </div>
            <div className="row mt-4 mt-lg-3">
              <div className="col-lg-4">
                <h4>Phone</h4>
              </div>
              <div className="col-lg-8">
                <a href="tel:+40728473359">+40728 473 359</a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <h1 className="d-lg-none">Get in touch!</h1>
            <ContactForm />
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

export { Contact };
