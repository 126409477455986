import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Card, Row, Button, FormGroup, FormLabel } from "react-bootstrap";

import { accountService, alertService } from "../../_services";
import { PageContainer } from "./Container";

function Login({ history, location }) {
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  function onSubmit({ email, password }, { setSubmitting }) {
    alertService.clear();
    accountService
      .login(email, password)
      .then(async () => {
        const { from } = location.state || {
          from: { pathname: "/admin/pages" },
        };

        history.push(from);
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <PageContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Card.Header>Login</Card.Header>

            <Card.Body>
              <FormGroup className="mb-2">
                <FormLabel>Email</FormLabel>
                <Field
                  name="email"
                  type="text"
                  className={
                    "form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <FormLabel>Password</FormLabel>
                <Field
                  name="password"
                  type="password"
                  className={
                    "form-control" +
                    (errors.password && touched.password ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>

              <Row>
                <FormGroup className="col">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Login
                  </Button>
                  <Link to="register" className="btn btn-link">
                    Register
                  </Link>
                </FormGroup>

                <FormGroup className="col text-end">
                  <Link to="forgot-password" className="btn btn-link pr-0">
                    Forgot Password?
                  </Link>
                </FormGroup>
              </Row>
            </Card.Body>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
}

export { Login };
