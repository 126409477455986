import React from "react";
import { Modal, Button } from "react-bootstrap";

function ConfirmationModal(props) {
  const { show, setShow, confirmAction, title, message, okText, cancelText } =
    props;

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={() => setShow(false)}>
          {cancelText}
        </Button>
        <Button variant="primary" size="sm" onClick={confirmAction}>
          {okText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export { ConfirmationModal };
