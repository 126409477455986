import React, { useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { Container } from "./pages";
import { AdminContainer } from "./admin";

import "./styles/styles.scss";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    const page = pathname.substring(1);
    const className = page ? page : "home";

    document.body.className = document.body.className.includes("dark")
      ? `dark ${className}`
      : className;
  }, [pathname]);

  return (
    <div className="app-container">
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route path="/admin" component={AdminContainer} />
        <Route path="/" component={Container} />
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
}

export default App;
