import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchPagesData } from "../store/actions/pageItemsActions";
import { ListItems } from "../_components/list-items/ListItems";
import { ContentArea } from "../_components/content-area/ContentArea";
import { DESTINATION } from "../constants";

import { PageLoader } from "../_components/page-loader";
import { useUtil } from "../_components/page-loader/context";

function Home() {
  const { data } = useSelector((state) => state.pageItems);

  const { friendlyURL } = useParams();

  const dispatch = useDispatch();

  const { pageLoaded, handleChangeState } = useUtil();

  useEffect(() => {
    if (data.length > 0) {
      handleChangeState();
    }

    dispatch(fetchPagesData());
  }, [data]);

  return (
    <React.Fragment>
      {pageLoaded ? (
        <ContentArea>
          <ListItems
            data={data}
            area={DESTINATION.HOME}
            friendlyURL={friendlyURL}
            columnsCountBreakPoints={{ 750: 2, 992: 3 }}
          />
        </ContentArea>
      ) : (
        <PageLoader />
      )}
    </React.Fragment>
  );
}

export { Home };
