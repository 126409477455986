export const sortItems = (area) => (firstEl, secondEl) => {
  if (firstEl.index[area] < secondEl.index[area]) {
    return -1;
  }

  if (firstEl.index[area] > secondEl.index[area]) {
    return 1;
  }

  return 0;
};
