import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useCookies } from "react-cookie";
import { StyledCloseButton } from "./ItemDetails.styled";
import Modal from "../modal/Modal";
import { ItemImage } from "./_partials/ItemImage";
import { ItemVideo } from "./_partials/ItemVideo";
import { ItemArticle } from "./_partials/ItemArticle";
import { ITEM_TYPE } from "../../constants";
import { history } from "../../_helpers";

const BACK_BUTTON_TEXT = {
  home: "HOME",
  photos: "STILLS",
  videos: "MOTION",
};

const ItemDetails = ({ item, show, setShow, area, friendlyURL }) => {
  const [dominantColor, setDominantColor] = useState("#ffffff");
  const [cookies] = useCookies(["dark-mode"]);

  const { pathname } = window.location;

  useEffect(() => {
    if (item) {
      switch (item.itemType) {
        case ITEM_TYPE.IMAGE:
          setDominantColor(
            item.image.dominantColor
              ? item.image.dominantColor
              : cookies["dark-mode"]
              ? "#000000"
              : "#ffffff"
          );
          break;
        case ITEM_TYPE.VIDEO:
          setDominantColor(
            item.videoDominantColor
              ? item.videoDominantColor
              : cookies["dark-mode"]
              ? "#000000"
              : "#ffffff"
          );
          break;
        default:
          setDominantColor(cookies["dark-mode"] ? "#000000" : "#ffffff");
      }
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      const itemFriendlyURL = item.friendlyURL ? item.friendlyURL : item.id;

      document.documentElement.classList.add("modal-open");

      if (!pathname.includes(friendlyURL)) {
        history.push(`${pathname === "/" ? "" : pathname}/${itemFriendlyURL}`);
      }
    }
  }, [show]);

  const renderItemDetail = (item) => {
    switch (item.itemType) {
      case ITEM_TYPE.IMAGE:
        return <ItemImage item={item} />;
      case ITEM_TYPE.VIDEO:
        return <ItemVideo item={item} />;
      case ITEM_TYPE.ARTICLE:
        return <ItemArticle item={item} />;
      default:
        return null;
    }
  };

  const handleClose = () => {
    history.push(
      `${pathname === "/" ? "" : pathname.replace(`/${friendlyURL}`, "")}`
    );
    setShow(false);
    document.documentElement.classList.remove("modal-open");
  };

  return (
    <>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {show && (
          <Modal handleClose={handleClose} dominantColor={dominantColor}>
            {item && renderItemDetail(item)}
            <StyledCloseButton onClick={handleClose}>
              {`Back to ${BACK_BUTTON_TEXT[area]}`}
            </StyledCloseButton>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export { ItemDetails };
