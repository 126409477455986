import styled, { keyframes } from "styled-components";

const into = keyframes`
0% {
opacity:0;
}
100% {
opacity:1;
}
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: ${into} 1s linear; */
  opacity: 1;
  transition: 250ms ease;
  z-index: 99999999999999999;

  .dark & {
    background-color: #000;
  }
`;

export const LoadedContainer = styled.div`
  max-width: 120px;
  width: 100%;
  max-height: 120px;
  height: 100%;
  position: relative;
`;
