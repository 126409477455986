import styled from "styled-components";

const StyledNavMenuMenu = styled.div`
  a {
    color: #a4a4a4;
    font-size: 20px;
    text-decoration: none;
    margin: 0 25px;

    &.active {
      color: #1c1c1c;
      position: relative;

      &::before {
        background-color: #1c1c1c;
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 0;
      }

      .dark & {
        color: #ffffff;

        &::before {
          background-color: #ffffff;
        }
      }
    }

    @media (max-width: 1200px) {
      font-size: 18px;
      margin: 0 20px;
    }
  }

  &.mobile-menu {
    position: fixed;
    padding-top: 60px;
    z-index: 999;
    top: 83px;
    left: 100vw;
    height: calc(100vh - 83px);
    width: 100%;
    background: #ffffff;
    transition: ease 500ms;

    &.is-opened {
      left: 0;
    }

    .dark & {
      background: #1c1c1c;
    }

    a {
      font-size: 2.125rem;
      font-weight: 400;
      line-height: 2;

      &.active {
        &::before {
          display: none;
        }
      }
    }
  }
`;

const StyledDarkSwitch = styled.div`
  display: flex;
  margin-left: 20px;

  img {
    width: 20px;
    margin-left: 10px;
  }

  .mobile-menu & {
    margin-top: 50px;
  }
`;

const StyledMenuLabel = styled.div`
  margin-left: 20px;
  color: #a4a4a4;
  font-size: 1rem;
  margin-bottom: 20px;
`;

export { StyledNavMenuMenu, StyledDarkSwitch, StyledMenuLabel };
