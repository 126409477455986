import React from "react";
import { motion } from "framer-motion";
import { PageHeaderStyled } from "./PageHeader.styled";

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const backVariants = {
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 0, ...transition } },
};

const PageHeader = ({ children, title }) => {
  return (
    <PageHeaderStyled
      className="ms-0 ms-sm-2 mt-3 mt-sm-5"
      initial="exit"
      animate="enter"
      exit="exit"
    >
      <motion.h1 className="main-title mb-3" variants={backVariants}>
        {title}
      </motion.h1>
      <motion.div
        className="col-xl-6 col-lg-8 col-md-12"
        variants={backVariants}
      >
        {children}
      </motion.div>
    </PageHeaderStyled>
  );
};

export { PageHeader };
