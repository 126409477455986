import * as ACTION from "../constants";
import { mediaService } from "../../_services";

export const clearMedia = () => (dispatch) => {
  dispatch({ type: ACTION.CLEAR_MEDIA });
};

export const fetchMedia = () => async (dispatch, getState) => {
  const state = getState();

  if (state.media.allImages.length > 0) {
    return;
  }

  const data = await mediaService.getAll();
  dispatch({ type: ACTION.FETCH_MEDIA });
  dispatch({ type: ACTION.FETCH_MEDIA_SUCCESS, payload: { data: data } });
};

export const addMedia =
  (mediaData, folder, dominantColor) => async (dispatch) => {
    dispatch({ type: ACTION.ADD_MEDIA });

    const data = await mediaService.addMedia(mediaData, folder, dominantColor);

    dispatch({ type: ACTION.ADD_MEDIA_SUCCESS, payload: data });
  };

export const updateMedia = (name, dominantColor) => async (dispatch) => {
  dispatch({ type: ACTION.UPDATE_MEDIA });

  const data = await mediaService.updateMedia(name, dominantColor);

  dispatch({
    type: ACTION.UPDATE_MEDIA_SUCCESS,
    payload: { name, dominantColor },
  });
};

export const deleteMedia = (media) => async (dispatch) => {
  dispatch({ type: ACTION.DELETE_MEDIA });

  const filename = media.itemName.replace("/", "--");

  await mediaService.deleteMedia(filename);

  dispatch({ type: ACTION.DELETE_MEDIA_SUCCESS, payload: media.itemId });
};
