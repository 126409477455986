import * as ACTION from "../constants";

const initialState = {
  data: [],
  error: false,
};

export const pageItemsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION.CLEAR_PAGE_ITEMS:
      return initialState;
    case ACTION.FETCH_PAGES_DATA:
      return state;
    case ACTION.FETCH_PAGES_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        data: payload,
      };
    case ACTION.ADD_PAGES_DATA:
      return state;
    case ACTION.ADD_PAGES_DATA_SUCCESS:
      return {
        ...state,
        data: [...state.data, payload],
      };
    case ACTION.UPDATE_PAGES_DATA:
      return state;
    case ACTION.UPDATE_PAGES_DATA_SUCCESS:
      state.data.forEach((item, index, data) => {
        if (item.id === payload.id) {
          data[index] = { ...data[index], ...payload };
        }
      });
      return {
        ...state,
        error: false,
      };
    case ACTION.FETCH_PAGES_DATA_FAIL:
    case ACTION.ADD_PAGES_DATA_FAIL:
    case ACTION.UPDATE_PAGE_ITEMS_POSITION_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
