import React, { useRef } from "react";
import ReactPlayer from "react-player/lazy";
import classNames from "classnames";

function VideoPreview({
  videoUrl,
  handleMouseLeave,
  handleClick,
  canBeDisplayed,
}) {
  const player = useRef(null);

  const handleOnProgress = () => {
    if (!canBeDisplayed) {
      player.current.seekTo(0);
    }
  };

  const videPreviewClassNames = classNames(
    "video-preview position-absolute top-0 start-0 w-100 h-100",
    {
      visible: canBeDisplayed,
    }
  );

  return (
    <div className={videPreviewClassNames} onMouseLeave={handleMouseLeave}>
      <div className="mask" onClick={handleClick}></div>
      <ReactPlayer
        ref={player}
        url={videoUrl}
        muted
        loop
        playing={canBeDisplayed}
        width="100%"
        height="100%"
        onProgress={handleOnProgress}
      />
    </div>
  );
}

export { VideoPreview };
