import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

function AccordionAddItem({ saveAccordion }) {
  const [accordion, setAccordion] = useState({
    title: "",
    content: "",
  });

  const handleAccordionAdd = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    const obj = {};

    obj[key] = value;

    setAccordion({ ...accordion, ...obj });
  };

  const handleSave = () => {
    saveAccordion(accordion);

    setAccordion({
      title: "",
      content: "",
    });
  };

  return (
    <Row className="mt-3">
      <Col md="6">
        <Form.Group as={Row} className="mb-3" controlId="title">
          <Form.Label column sm={2}>
            Title
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              name="title"
              onChange={handleAccordionAdd}
              value={accordion.title}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="content">
          <Form.Label column sm={2}>
            Content
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="textarea"
              name="content"
              onChange={handleAccordionAdd}
              value={accordion.content}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col sm={{ span: 10, offset: 2 }}>
            <Button size="sm" onClick={handleSave}>
              Save
            </Button>
          </Col>
        </Form.Group>
      </Col>
    </Row>
  );
}

export { AccordionAddItem };
