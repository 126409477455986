import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { PageHeader } from "../_components/page-header/PageHeader";
import { ContentArea } from "../_components/content-area/ContentArea";
import { fetchPagesData } from "../store/actions/pageItemsActions";
import { ListItems } from "../_components/list-items/ListItems";
import { DESTINATION } from "../constants";

import { PageLoader } from "../_components/page-loader";
import { useUtil } from "../_components/page-loader/context";

function Stils() {
  const { data } = useSelector((state) => state.pageItems);

  const { friendlyURL } = useParams();

  const dispatch = useDispatch();

  const { pageLoaded, handleChangeState } = useUtil();

  useEffect(() => {
    if (data.length > 0) {
      handleChangeState();
    }
    dispatch(fetchPagesData());
  }, [data]);

  return (
    <React.Fragment>
      {pageLoaded ? (
        <React.Fragment>
          <Container>
            <PageHeader title="Stills">
              Harvesting the potential of cutting-edge technology, along with
              innovative techniques, we strive to make every still-life
              photography a complex story. We believe a strong composition is
              able to guide the viewer’s eyes through the image and enable him
              to fully understand it. Our ultimate goal is to bring tailor-made
              solutions to any creative need.
            </PageHeader>
          </Container>
          <ContentArea>
            <ListItems
              data={data}
              area={DESTINATION.STILS}
              friendlyURL={friendlyURL}
              columnsCountBreakPoints={{ 400: 2, 750: 2, 992: 3 }}
            />
          </ContentArea>
        </React.Fragment>
      ) : (
        <PageLoader />
      )}
    </React.Fragment>
  );
}

export { Stils };
