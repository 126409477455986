import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Container, Card, Button, Row, Col } from "react-bootstrap";
import { MediaItem } from "../_components";
import { ConfirmationModal, UploadMediaModal } from "../_components/modals";
import { deleteMedia } from "../../store/actions/mediaActions";
import { useEffect } from "react";
import { BUCKET_URL } from "../../constants";

function Media() {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const [images, setImages] = useState([]);
  const [thumbs, setThumbs] = useState([]);
  const [videos, setVideos] = useState([]);

  const { allImages } = useSelector((state) => state.media);

  useEffect(() => {
    const temp = {
      images: [],
      thumbs: [],
      videos: [],
    };

    allImages.map((item) => {
      const folder = item.url.split(BUCKET_URL).pop().split("/")[0];

      temp[folder].push(item);
    });

    setImages(temp.images);
    setThumbs(temp.thumbs);
    setVideos(temp.videos);
  }, [allImages, showUploadModal]);

  const dispatch = useDispatch();

  const handleDeleteMedia = (itemId, itemName) => {
    setShowConfirmationModal(true);
    setDeleteItem({ itemId, itemName });
  };

  const handleDelete = () => {
    dispatch(deleteMedia(deleteItem));
    setShowConfirmationModal(false);
    setDeleteItem(null);
  };

  const handleUploadModal = (type) => {
    setMediaType(type);
    setShowUploadModal(true);
  };

  const handleEdit = (item) => {
    setShowUploadModal(true);
    setActiveItem(item);
    console.log(item);
  };

  const handleCloseModal = () => {
    console.log("handle close");
    setShowUploadModal(false);
    setActiveItem(null);
  };

  return (
    <div className="p-4">
      <Container>
        <h1>Media</h1>
        <Card className="mb-3">
          <Card.Header>All Images</Card.Header>

          <Card.Body>
            {images.map((image, idx) => (
              <MediaItem
                item={image}
                removeText="Delete"
                key={`images-${idx}`}
                removeHandler={() => handleDeleteMedia(image.id, image.name)}
                clickHandler={() => handleEdit(image)}
              />
            ))}
          </Card.Body>

          <Card.Footer className="text-end">
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleUploadModal("images")}
            >
              Add Images
            </Button>
          </Card.Footer>
        </Card>

        <Card className="mb-3">
          <Card.Header>All Thumbs</Card.Header>

          <Card.Body>
            {thumbs.map((image, idx) => (
              <MediaItem
                item={image}
                removeText="Delete"
                key={`thumbs-${idx}`}
                removeHandler={() => handleDeleteMedia(image.id, image.name)}
                clickHandler={() => handleEdit(image)}
              />
            ))}
          </Card.Body>

          <Card.Footer className="text-end">
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleUploadModal("thumbs")}
            >
              Add Thumb
            </Button>
          </Card.Footer>
        </Card>
      </Container>

      <ConfirmationModal
        show={showConfirmationModal}
        setShow={setShowConfirmationModal}
        confirmAction={handleDelete}
        title="Delete media"
        message="Are you sure you want to delete this item?"
        okText="Delete"
        cancelText="Cancel"
      />

      <UploadMediaModal
        show={showUploadModal}
        closeModal={handleCloseModal}
        mediaType={mediaType}
        activeItem={activeItem}
      />
    </div>
  );
}

export { Media };
