import styled from "styled-components";
import { motion } from "framer-motion";
import CloseIcon from "../../assets/close-icon_old.svg";
import CloseIconDark from "../../assets/close-icon-dark_old.svg";

const ModalStyled = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 12px;
  flex-direction: column;
  cursor: url(${CloseIcon}), auto;

  .dark & {
    cursor: url(${CloseIconDark}), auto;
  }
`;

export { ModalStyled };
