import styled from "styled-components";

const StyledNavbar = styled.div`
  background-color: #ffffff;
  padding: 30px 20px !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  @media (max-width: 576px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .dark & {
    background-color: #1c1c1c;
  }

  .modal-open & {
    filter: blur(7px);
  }
`;

const StyledLogo = styled.div`
  img {
    width: 220px;

    @media (max-width: 1200px) {
      width: 170px;
    }

    @media (max-width: 576px) {
      width: 140px;
    }
  }
`;

export { StyledNavbar, StyledLogo };
