export const CLEAR_MEDIA = "CLEAR_MEDIA";
export const CLEAR_PAGE_ITEMS = "CLEAR_PAGE_ITEMS";

export const FETCH_MEDIA = "FETCH_MEDIA";
export const FETCH_MEDIA_SUCCESS = "FETCH_MEDIA_SUCCESS";
export const FETCH_MEDIA_FAIL = "FETCH_MEDIA_FAIL";

export const ADD_MEDIA = "ADD_MEDIA";
export const ADD_MEDIA_SUCCESS = "ADD_MEDIA_SUCCESS";
export const ADD_MEDIA_FAIL = "ADD_MEDIA_FAIL";

export const UPDATE_MEDIA = "UPDATE_MEDIA";
export const UPDATE_MEDIA_SUCCESS = "UPDATE_MEDIA_SUCCESS";
export const UPDATE_MEDIA_FAIL = "UPDATE_MEDIA_FAIL";

export const DELETE_MEDIA = "DELETE_MEDIA";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_FAIL = "DELETE_MEDIA_FAIL";

export const FETCH_PAGES_DATA = "FETCH_PAGES_DATA";
export const FETCH_PAGES_DATA_SUCCESS = "FETCH_PAGES_DATA_SUCCESS";
export const FETCH_PAGES_DATA_FAIL = "FETCH_PAGES_DATA_FAIL";

export const ADD_PAGES_DATA = "ADD_PAGES_DATA";
export const ADD_PAGES_DATA_SUCCESS = "ADD_PAGES_DATA_SUCCESS";
export const ADD_PAGES_DATA_FAIL = "ADD_PAGES_DATA_FAIL";

export const DELETE_PAGES_DATA = "DELETE_PAGES_DATA";
export const DELETE_PAGES_DATA_SUCCESS = "DELETE_PAGES_DATA_SUCCESS";
export const DELETE_PAGES_DATA_FAIL = "DELETE_PAGES_DATA_FAIL";

export const UPDATE_PAGES_DATA = "UPDATE_PAGES_DATA";
export const UPDATE_PAGES_DATA_SUCCESS = "UPDATE_PAGES_DATA_SUCCESS";
export const UPDATE_PAGES_DATA_FAIL = "UPDATE_PAGES_DATA_FAIL";

export const UPDATE_PAGE_ITEMS_POSITION = "UPDATE_PAGE_ITEMS_POSITION";
export const UPDATE_PAGE_ITEMS_POSITION_SUCCESS =
  "UPDATE_PAGE_ITEMS_POSITION_SUCCESS";
export const UPDATE_PAGE_ITEMS_POSITION_FAIL =
  "UPDATE_PAGE_ITEMS_POSITION_FAIL";
