import React from "react";

import { Button, Figure } from "react-bootstrap";

function MediaItem({
  item,
  removeText = null,
  removeHandler = null,
  clickHandler = null,
  activeItemId = null,
}) {
  const mediaUrl =
    item.contentType.split("/")[0] === "image"
      ? item.url
      : "/video-thumbnail.jpeg";

  return (
    <div className={`media-item ${activeItemId === item.id ? "active" : ""}`}>
      <Figure.Image
        width={100}
        height={100}
        key={item.id}
        src={mediaUrl}
        className="p-1 link"
        onClick={clickHandler}
      />
      {removeText && removeHandler ? (
        <Button
          variant="danger"
          size="sm"
          className="remove-btn"
          onClick={removeHandler}
        >
          {removeText}
        </Button>
      ) : null}
    </div>
  );
}

export { MediaItem };
