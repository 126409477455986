import React from "react";
import { StyledLoader } from "../item-details/ItemDetails.styled";
import lottie from "lottie-web/build/player/lottie_light";
import animationData from "../item-details/_partials/animationData.json";
import * as style from "./style.styled";

const PageLoader = (props) => {
  const animationContainer = React.useRef(null);
  const anim = React.useRef(null);

  React.useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData,
      });

      return () => {
        anim.current?.destroy();
      };
    }
  }, []);

  return (
    <style.Wrapper>
      <style.LoadedContainer>
        <StyledLoader
          ref={animationContainer}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        />
      </style.LoadedContainer>
    </style.Wrapper>
  );
};

export { PageLoader };
