import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Card, Row, Col } from "react-bootstrap";
import { MediaItem } from "../index";
import { BUCKET_URL } from "../../../constants";

function SelectMediaModal(props) {
  const { show, setShow, mediaType, mediaObject, setMediaObject } = props;
  const [activeItem, setActiveItem] = useState(null);

  const [images, setImages] = useState([]);
  const [thumbs, setThumbs] = useState([]);
  const [videos, setVideos] = useState([]);

  const { allImages } = useSelector((state) => state.media);

  useEffect(() => {
    const temp = {
      images: [],
      thumbs: [],
      videos: [],
    };

    allImages.map((item) => {
      const folder = item.url.split(BUCKET_URL).pop().split("/")[0];

      temp[folder].push(item);

      setImages(temp.images);
      setThumbs(temp.thumbs);
      setVideos(temp.videos);
    });
  }, [allImages]);

  const handleSetActiveItem = (item) => {
    setActiveItem(item);
  };

  const handleMediaSelect = () => {
    setShow(false);

    const updatedMediaObject = {};
    updatedMediaObject[mediaType] = activeItem;

    setMediaObject({
      ...mediaObject,
      ...updatedMediaObject,
    });
  };

  return (
    <Modal
      className="select-media-modal"
      show={show}
      fullscreen={true}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Media for {mediaType}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3">
          <Card.Header>Images</Card.Header>

          <Card.Body>
            {images.map((image, idx) => (
              <MediaItem
                item={image}
                key={idx}
                activeItemId={activeItem ? activeItem.id : null}
                clickHandler={() => handleSetActiveItem(image)}
              />
            ))}
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Header>Thumbs</Card.Header>

          <Card.Body>
            {thumbs.map((image, idx) => (
              <MediaItem
                item={image}
                key={idx}
                activeItemId={activeItem ? activeItem.id : null}
                clickHandler={() => handleSetActiveItem(image)}
              />
            ))}
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Header>All Videos</Card.Header>

          <Card.Body>
            <Row className="video-list">
              {videos.map((image, idx) => (
                <Col lg={4} className="d-flex" key={idx}>
                  <MediaItem
                    item={image}
                    key={idx}
                    activeItemId={activeItem ? activeItem.id : null}
                    clickHandler={() => handleSetActiveItem(image)}
                  />
                  <div className="details d-flex flex-column justify-content-start">
                    <span className="name">{image.name}</span>
                    <a href={image.url} className="url" target="_blank">
                      Preview
                    </a>
                  </div>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleMediaSelect}>Select media</Button>
      </Modal.Footer>
    </Modal>
  );
}

export { SelectMediaModal };
