import React, { useEffect, useRef } from "react";
import lottie from "lottie-web/build/player/lottie_light";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { StyledHamburger } from "./BurgerMenu.styled";
import animationData from "./animationData.json";
import animationDataDark from "./animationDataDark.json";

function BurgerMenu({ open, setOpen }) {
  const animationContainer = useRef(null);
  const anim = useRef(null);

  const [cookies] = useCookies(["dark-mode"]);

  const location = useLocation();

  useEffect(() => {
    if (document.body.className.includes("menu-opened")) {
      anim.current?.setDirection(open ? -1 : 1);
      anim.current?.play();
    }
  }, [location]);

  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: cookies["dark-mode"] ? animationDataDark : animationData,
      });

      if (document.body.className.includes("menu-opened")) {
        anim.current.play();
      }

      return () => {
        anim.current?.destroy();
      };
    }
  }, [cookies]);

  return (
    <StyledHamburger
      onClick={() => {
        setOpen(!open);
        anim.current?.setDirection(open ? -1 : 1);
        anim.current?.play();
      }}
      ref={animationContainer}
    />
  );
}

export { BurgerMenu };
